<script>
// import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
// import { required } from "vuelidate/lib/validators";
import { paymentMethods } from "@/state/helpers";
import * as cloneDeep from "lodash.clonedeep";
import Multiselect from 'vue-multiselect';
import {
  FETCH_ALL_PAYMENT_CHANNEL,
  ONBOARDING_STATUS_CHECK,
  FETCH_MERCHANTS_LIST
} from "@/state/actions.type";
// import FilterBtn from '../../../../components/filter-btn.vue';
import Common from "../../../../helpers/Common";

export default {
  components: {
    // Layout,
    // PageHeader,
    // FilterBtn,
    Multiselect
  },
  props: {
      isForReconChannels: Boolean
  },

  data() {
    return {
      isPreviewOpen:false,
      merchant_uuids:"",
      paymentChannelCreds: null,
      countryForFilter:[],
      paymentMethodForFilter:"",
      showFilterSection:false,
      isFilterApplied: true,
      countries: []
    };
  },

  computed: {
    paymentChannelCredsList() {
      return this.$store.state.paymentChannels.channels;
    },

    chaiPayKey() {
      return this.$store.getters["auth/iamportKey"];
    },

    userCountry() {
      return this.$store.getters["auth/getCurrentUserCountry"];
    },
    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    paymentChaneelSetupdata() {
      return this.$store.getters["paymentChannels/paymentChaneelSetupdata"];
    },

    paymentChaneelNotSetupdata() {
      return this.$store.getters["paymentChannels/paymentChaneelNotSetupdata"];
    },
    reconChaneelSetupdata() {
      return this.$store.getters["paymentChannels/reconChaneelSetupdata"];
    },

    reconChaneelNotSetupdata() {
      return this.$store.getters["paymentChannels/reconChaneelNotSetupdata"];
    },

    // items() {
    //   return [
    //     {
    //       text: this.$t("menuitems.integration.list.payconfig"),
    //       active: true
    //     },
    //     {
    //       text: this.$route.query.open === "checkout-preview" ? "Checkout Preview" : this.$t("menuitems.integration.list.pay-channels"),
    //       active: true,
    //     },
    //   ];
    // },

    // title() {
    //   return this.$route.query.open === "checkout-preview" ? "Checkout Preview" : this.$t("menuitems.integration.list.pay-channels");
    //   // return this.$t("menuitems.integration.list.pay-channelstwo");
    // },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },
  },

  created() {
    this.countries = Common.countryList();
    this.isPreviewOpen = this.$route.query.open === "checkout-preview"
    this.countryForFilter = [ this.userCountry, 'GLOBAL' ];
    if(!this.isPreviewOpen){
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
        country: this.countryForFilter,
        paymentMethod: this.paymentMethodForFilter,
        merchant_uuids:this.merchant_uuids,
        paymentType: "PAYIN"
      });
      this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    }else{
      this.getMethodsFromCurrency()
    }
    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      });
    }
  },

  watch: {
    paymentChannelCredsList() {
      this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
    },
    merchant_uuids() {
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
        country: this.countryForFilter,
        paymentMethod: this.paymentMethodForFilter,
        merchant_uuids:this.merchant_uuids.uuid,
        paymentType: "PAYIN"
      });
    },
  },

  mounted() {
    this.paymentChannelCreds = cloneDeep(this.paymentChannelCredsList);
  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  validations: {
    paymentChannelCreds: {},
  },

  methods: {
    ...paymentMethods,
    onCopyWebhookURL() {
      this.$notify({
        type: "success",
        text: `PortOne provided webhook for ${this.currentChannel} copied to clipboard.`,
        closeOnClick: true,
      });
    },
    openFilter(){
      this.showFilterSection = !this.showFilterSection
    },
    saveFilter(){
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
        country: this.countryForFilter,
        paymentMethod: this.paymentMethodForFilter,
        merchant_uuids:this.merchant_uuids.uuid
      });
      this.showFilterSection = false;
      if(this.countryForFilter.length > 0 || this.paymentMethodForFilter) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
    },
    clearFilter(){
      this.countryForFilter = []
      this.paymentMethodForFilter = ""
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, {
        country: this.countryForFilter,
        paymentMethod: this.paymentMethodForFilter,
        merchant_uuids:this.merchant_uuids.uuid
      });
      this.showFilterSection = false;
      this.isFilterApplied = false;
    },
    checkOnboardingStatus(data){
      // if(data.onboarding_status["status"] !== "Completed" && !data.onboarding_type_default){ 
      if(!data.onboarding_type_default){
        if(['paypal', 'stripe'].includes(data.key.toLowerCase())){
          this.$store.dispatch(`paymentChannels/${ONBOARDING_STATUS_CHECK}`, {
            chaiPayKey: this.chaiPayKey,
            merchant_uuids:this.merchant_uuids,
            psp: data.key.toLowerCase()
          }).then((status) => {
           // console.log(data.onboarding_status.status)
              if(status.status == "Completed"){
                this.$router.push({ path: '/settings/pay-channel/update-config', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", uuid: this.merchant_uuids.uuid ? this.merchant_uuids.uuid : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
              }else if(status.status == "Pending"){
                if(data.key.toLowerCase() == "paypal") {
                  if(!status.isEmailConfirmed){
                      this.$notify({
                        type: "error",
                        text: this.$t("views.payment_channels.confirm_email_paypal"),
                        closeOnClick: true,
                      });
                  }else if(!status.paymentsReceivable){
                      this.$notify({
                        type: "error",
                        text: this.$t("views.payment_channels.paypal_not_actived"),
                        closeOnClick: true,
                      });
                  }else if(!status.scopesAvailable){
                    this.$router.push({ path: '/settings/pay-channel/update-config-onboarding', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
                  }
                } else {
                  this.$router.push({ path: '/settings/pay-channel/update-config-onboarding', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
                }
              }else{
                this.$router.push({ path: '/settings/pay-channel/update-config-onboarding', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
              }
          })
          .catch((error) => {
            // error
            console.log("error", error);
          });
        }

        else if(data.key.toLowerCase() == 'gbprimepay'){
          // this.$store.dispatch(`paymentChannels/${ONBOARDING_STATUS_CHECK}`, {
          //   chaiPayKey: this.chaiPayKey
          // }).then((status) => {
            //console.log(status)
              if(data.onboarding_status.status == "Completed"){
                this.$router.push({ path: '/settings/pay-channel/update-config', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", uuid: this.merchant_uuids.uuid ? this.merchant_uuids.uuid : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
              }else if(data.onboarding_status.status == "Rejected"){
                // if(!status.isEmailConfirmed){
                //     this.$notify({
                //       type: "error",
                //       text: `Kindly check your email inbox message and confirm the GBPrimePay email to activate GBPrimePay`,
                //       closeOnClick: true,
                //     });
                 
                    this.$notify({
                      type: "error",
                      text: this.$t("views.payment_channels.bgpp_rejected_registration"),
                      closeOnClick: true,
                    });
                
              }
              else if(data.onboarding_status.status === "Pending"){
                
                 
                    // this.$notify({
                    //   type: "error",
                    //   text: `GBPrimePay is validating your registration data, Kindly wait for some time.`,
                    //   closeOnClick: true,
                    // });
                    this.$router.push({ path: '/settings/pay-channel/update-config', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", uuid: this.merchant_uuids.uuid ? this.merchant_uuids.uuid : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
                
              }
              else{
                this.$router.push({ path: '/settings/pay-channel/update-config', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", uuid: this.merchant_uuids.uuid ? this.merchant_uuids.uuid : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
              }
          // })
          // .catch((error) => {
          //   // error
          //   console.log("error", error);
          // });
        }
         
        else{
          // do changes for other onboarding
        }         
      }else{
        this.$router.push({ path: '/settings/pay-channel/update-config', query: { channelName: data.key, email: this.merchant_uuids.email_id ? this.merchant_uuids.email_id : "", uuid: this.merchant_uuids.uuid ? this.merchant_uuids.uuid : "", key: this.merchant_uuids.key ? this.merchant_uuids.key : "" } })
      }
    },
    paymentChannel(key) {
      if(key.toLowerCase() === "paypal") {
        return 'PayPal';
      }
      if(key === "PORTONE_KR") {
        return "PORTONE Korea";
      }
      return key;
    },
    getRegionName(countryCode) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
      return regionNames.of(countryCode);
    },
  },
}; 
</script>

<template>
  <div>
    <!-- <PageHeader /> -->
    <div class="">
      <div class="pt-0 position-relative">
          <div class="position-relative">
            <b-btn @click="openFilter()" style="position: absolute;right: 10px; bottom:37px;" class="filter-button" variant="white">
              <img style="padding-right: 10px;" src="@/assets/images/filter.svg" alt="Filter">{{ $t('common.filters') }}
            </b-btn>
            <div v-if="showFilterSection" class="filter-section">
              <div class="top-section">
                  <div class="filter_channel">
                    <div class="filter_heading">{{ $t('views.payment_channels.country') }}</div>
                      <div class="filter_channel_section">
                        <div class="checkbox-parent" :class="{ 'active': countryForFilter.indexOf('GLOBAL') > -1 }">
                          <div class="checkbox-group"> 
                            <input type="checkbox" id="filter_global" value="GLOBAL" v-model="countryForFilter">
                            <label for="filter_global">{{ $t('views.payment_channels.global') }}</label>
                          </div>
                        </div>
                        <div class="checkbox-parent" v-for="country in countries" :key="country" :class="{ 'active': countryForFilter.indexOf(country) > -1 }" >
                          <div class="checkbox-group">  
                            <input type="checkbox" :id="`filter_${country}`" :value="country" v-model="countryForFilter">
                            <label :for="`filter_${country}`">{{ getRegionName(country) }}</label>
                          </div>
                        </div>
                      </div>
                  </div>
                  <!-- <div class="filter_method"> 
                      <div class="filter_heading">{{ $t('views.payment_channels.payment_methods') }}</div>
                      <div class="checkbox-group">  
                        <input type="radio" id="filter_wallet" value="WALLET" v-model="paymentMethodForFilter">
                        <label for="filter_wallet">Wallet</label>
                      </div>
                      <div class="checkbox-group">  
                        <input type="radio" id="filter_credit_card" value="INT_CREDIT_CARD" v-model="paymentMethodForFilter">
                        <label for="filter_credit_card">Credit Card</label>
                      </div>
                      <div class="checkbox-group"> 
                        <input type="radio" id="filter_atm_card" value="ATM_CARD" v-model="paymentMethodForFilter">
                        <label for="filter_atm_card">ATM Card</label>
                      </div>
                  </div> -->
              </div>
              <div class="bottom-section float-right">
                <button @click="clearFilter()" class="mr-2 btn font-16px btn-link">Cancel</button>
                <button @click="saveFilter()" class="btn text-chai font-16px btn-link">
                  {{ $t("button.apply") }}
                </button>
              </div>
            </div>
          </div>
          <!-- <b-tabs nav-class="nav-tabs-custom"> -->
          <!-- Payment Channel tab -->
          <!-- <b-tab
            :title="$t('views.payments.transactions.payment_channels')"
            title-link-class="p-3"
            v-if="(userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payment-channel-configurator') || userRoles.includes('payment-admin')) && !this.isPreviewOpen"
          > -->
            <div class="row d-flex justify-content-between align-items-baseline">
              <div class="col-md-4 d-flex">
                <!-- <FilterBtn :isActive="isFilterApplied" /> -->
              </div>
              <div class="col-md-4">
                <div v-if="isMasterMerchant" class="dynamic-card mt-3">
                  <multiselect
                    v-model="merchant_uuids"
                    :options="merchantListDataFromStore"
                    :placeholder="$t('common.search_merchants')"
                    track-by="email_id"
                    label="email_id"
                    class="sm-multiselect"
                    :searchable="true"
                    :multiple="false"
                    :taggable="true"
                  ></multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div v-if="Object.keys(reconChaneelSetupdata).length > 0 && isForReconChannels" style="display: flex;align-items: center;margin-bottom: 24px;" class="col-12">
                <p style="margin-bottom: 0px;width: 220px;">Connected Channels</p>
                <div class="channel-separator"></div>
              </div>
              <div v-if="Object.keys(paymentChaneelSetupdata).length > 0 && !isForReconChannels" style="display: flex;align-items: center;margin-bottom: 24px;" class="col-12">
                <p style="margin-bottom: 0px;width: 220px;">Connected Channels</p>
                <div class="channel-separator"></div>
              </div>
            </div>
              <div v-if="isForReconChannels" class="row">
                <div
                 class="col-xl-4 col-md-4 col-lg-4 col-sm-6 dynamic-card-parent"
                  v-for="(data, index) in reconChaneelSetupdata"
                  :key="index"
                >
                  <div
                    class="card dynamic-card"
                  >
                    <div class="card-body card-body-custom">
                      <div @click="checkOnboardingStatus(data)" class="">
                      <div class="img-container">
                        <img
                          :src="`${data.logo}`"
                          alt
                          class="avatar-lg"
                          style="object-fit: contain;position: relative; width: 70px;height: 60px;"
                        />
                        <p style="margin-bottom: 0px;font-size: 18px;font-weight: 400;padding-left: 25px;">{{ paymentChannel(data.key) }}</p>
                      </div>
                      <p class="description-class" >{{data.description}}</p>
                        <!-- <div class="avatar-background avatar-lg"></div> -->
                        <div class="media-body media-body-custom">
                          <p class="mb-0 d-inline-block">
                            {{ data.type }}
                          </p>
                          <img v-if="!data.is_enabled" src="@/assets/images/plus.png" alt />
                          <img v-if="data.is_enabled" src="@/assets/images/green_tick.png" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div
                class="col-xl-4 col-md-4 col-lg-4 col-sm-6 dynamic-card-parent"
                  v-for="(data, index) in paymentChaneelSetupdata"
                  :key="index"
                >
                  <div
                    class="card dynamic-card"
                  >
                    <div class="card-body card-body-custom">
                      <div @click="checkOnboardingStatus(data)" class="">
                      <div class="img-container">
                        <img
                          :src="`${data.logo}`"
                          alt
                          class="avatar-lg"
                          style="object-fit: contain;position: relative; width: 70px;height: 60px;"
                        />
                        <p style="margin-bottom: 0px;font-size: 18px;font-weight: 400;padding-left: 25px;">{{ paymentChannel(data.key) }}</p>
                      </div>
                      <p class="description-class" >{{data.description}}</p>
                        <!-- <div class="avatar-background avatar-lg"></div> -->
                        <div class="media-body media-body-custom">
                          <p class="mb-0 d-inline-block">
                            {{ data.type }}
                          </p>
                          <img v-if="!data.is_enabled" src="@/assets/images/plus.png" alt />
                          <img v-if="data.is_enabled" src="@/assets/images/green_tick.png" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div v-if="Object.keys(reconChaneelNotSetupdata).length > 0 && isForReconChannels" style="display: flex;align-items: center;margin-bottom: 24px;" class="col-12">
                  <p style="margin-bottom: 0px;width: 220px;">Available Channels</p>
                  <div class="channel-separator"></div>
                </div>
                <div v-if="Object.keys(paymentChaneelNotSetupdata).length > 0 && !isForReconChannels" style="display: flex;align-items: center;margin-bottom: 24px;" class="col-12">
                  <p style="margin-bottom: 0px;width: 220px;">Available Channels</p>
                  <div class="channel-separator"></div>
                </div>
              </div>
              <div v-if="isForReconChannels" class="row">
                <div
                  class="col-xl-4 col-md-4 col-lg-4 col-sm-6 dynamic-card-parent"
                  v-for="(data, index) in reconChaneelNotSetupdata"
                  :key="index"
                >
                  <div
                    class="card dynamic-card"
                  >
                    <div class="card-body card-body-custom">
                      <div @click="checkOnboardingStatus(data)" class="">
                      <div class="img-container">
                        <img
                          :src="`${data.logo}`"
                          alt
                          class="avatar-lg"
                          style="object-fit: contain;position: relative; width: 70px;height: 60px;"
                        />
                        <p style="margin-bottom: 0px;font-size: 18px;font-weight: 400;padding-left: 25px;">{{ paymentChannel(data.key) }}</p>
                      </div>
                      <p class="description-class" >{{data.description}}</p>
                        <!-- <div class="avatar-background avatar-lg"></div> -->
                        <div class="media-body media-body-custom">
                          <p class="mb-0 d-inline-block">
                            {{ data.type }}
                          </p>
                          <img v-if="!data.is_enabled" src="@/assets/images/plus.png" alt />
                          <img v-if="data.is_enabled" src="@/assets/images/green_tick.png" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div
                class="col-xl-4 col-md-4 col-lg-4 col-sm-6 dynamic-card-parent"
                  v-for="(data, index) in paymentChaneelNotSetupdata"
                  :key="index"
                >
                  <div
                    class="card dynamic-card"
                  >
                    <div class="card-body card-body-custom">
                      <div @click="checkOnboardingStatus(data)" class="">
                      <div class="img-container">
                        <img
                          :src="`${data.logo}`"
                          alt
                          class="avatar-lg"
                          style="object-fit: contain;position: relative; width: 70px;height: 60px;"
                        />
                        <p style="margin-bottom: 0px;font-size: 18px;font-weight: 400;padding-left: 25px;">{{ paymentChannel(data.key) }}</p>
                      </div>
                      <p class="description-class" >{{data.description}}</p>
                        <!-- <div class="avatar-background avatar-lg"></div> -->
                        <div class="media-body media-body-custom">
                          <p class="mb-0 d-inline-block">
                            {{ data.type }}
                          </p>
                          <img v-if="!data.is_enabled" src="@/assets/images/plus.png" alt />
                          <img v-if="data.is_enabled" src="@/assets/images/green_tick.png" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          <!-- </b-tab>
          <b-tab
            :title="$t('views.payments.transactions.checkout_preview')"
            title-link-class="p-3"
            @click="getMethodsFromCurrency()"
            v-if="userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payout-channel-configurator')"
          > -->
          <!-- </b-tab>
          <b-tab
            :title="$t('views.payments.transactions.routing')"
            title-link-class="p-3"
            v-if="(userRoles.includes('admin') || userRoles.includes('sub-admin') || userRoles.includes('payment-channel-configurator') || userRoles.includes('payment-admin')) && !isPreviewOpen && !isMasterMerchant"
          > -->
            <!-- <SmartRouting v-if="activeTab === 'routing'" /> -->
          <!-- </b-tab> -->
        <!-- </b-tabs> -->
      </div>
    </div>
  </div>
</template>

<style scoped>
input:focus:required:invalid {
  color: green;
}

::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.card-body-custom{
  padding:0px;
  cursor: pointer;
}
.media-body-custom{
  font-size: 14px;
  font-weight: 325;
  color: rgba(0, 0, 0, 0.50);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.media-body-custom img{
  width: 50px;
  height: 50px;
}
.dynamic-card{
  margin: auto;
  margin-bottom: 24px;
  box-shadow: none;
  border-radius: 15px;
  padding: 30px;
  border: 1px #DCDAD5 solid;
}
.dynamic-card:hover {
  border: 1px #FC6B2D solid;
  box-shadow: 0px 0px 4px 2px rgba(252, 107, 45, 0.50);
}
::v-deep .nav-tabs > li > a{
  color: #B3AFAF;
}
::v-deep .nav-tabs-custom .nav-item .nav-link.active{
  color:#252B3B;
}
::v-deep .nav-tabs-custom .nav-item .nav-link::after{
  background:#252B3B;
}
.channel-separator{
  border-bottom: 1px solid #DCDAD5;
  width: calc(100% - 220px);
}
@media (max-width: 575px) {
  ::v-deep .page-title-box{
    display: block !important;
    padding-bottom: 10px;
  }
  ::v-deep .page-title-box h4{
    margin-bottom: 5px!important;
  }
}
.checkout-preview-card{
  background:none;
}
@media (min-width: 768px) {
  .checkout-preview-card{
    border: none !important;
    box-shadow: none;
  }
}
.filter-section{
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
    background: #fff;
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 300px;
    right: 0px;
    border-radius: 15px;
}
/* .top-section{
    border-bottom: 1px solid #F0F0F0;
    padding: 20px;
    padding-left: 35px;
} */
.filter_channel_section{
  height: 350px;
  overflow-y: scroll;
}
.clear_filter{
  color: #BFBFBF;
  display: inline-block;
  margin-left: 15px;
  font-size: 13px;
  cursor: pointer;
}
.bottom-section{
    padding: 15px;
    padding-left: 35px;
}
.filter_heading{
    padding: 25px 25px 15px 25px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.50);
}
.checkbox-group{
  position: relative;
}
.checkbox-group input{
  display: none;
}
.checkbox-group  label {
  cursor: pointer;
  font-size: 13px;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 15px;
  bottom:1px;
}
.checkbox-group input:checked+label:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0px 2px 2px 0px;
  transform: rotate(45deg);
}
/* .filter_channel,.filter_method{
  display: inline-block;
  vertical-align: top;
} */
.filter_method{
    float: right;
    padding-right: 30px;
}
.lang-menu select {
    padding: 3px 10px;
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    color: #828282;
    font-size: 12px;
}
::v-deep .main-content{
      overflow: visible;
}
.color-picker {
  position: absolute;
  overflow: hidden;
  width: 34px;
  height: 36px;
  top: 1px;
  left: 13px;
  border: none;
  border-right: 1px solid #cfdadd;
  border-radius: 2px;
  display: inline-block;
}

input[type="color"] {
  position: absolute;
  right: -8px;
  top: -8px;
  height: 51px;
  width: 48px;
  border: none;
}
.img-container{
  display: flex;
  align-items: center;
  justify-content: left;
}
.filter-button {
    border-radius: 12px;
    padding: 12px 16px;
    height: 44px;
    border: 1px solid #DCDAD5;
}
.checkbox-parent{
  padding: 15px 25px 15px 25px;
  border-bottom: 1px rgba(220, 218, 213, 0.30) solid;
}
.checkbox-parent.active{
    background: rgba(220, 218, 213, 0.30);
}
.checkbox-parent label{
  margin-bottom: 0px;
  font-size: 15px;
}
.description-class{
  padding-top: 10px;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.70);
  margin-bottom: 0px;
  padding-bottom: 15px;
  height: 75px;
}
@media (max-width: 1500px) {
  .description-class{
    font-size: 12px;
    height: 75px;
  }
}

</style>